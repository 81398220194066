import { createSelector } from 'reselect';
import { AppState } from '../../store/AppState';
import { getLocalisedRoute } from 'constants/urls';
import motImage from '../../assets/images/mot.svg';
import brakeImage from '../../assets/images/brakes.svg';
import wheelsImage from '../../assets/images/wheels.svg';
import tyreImage from '../../assets/images/tyres.svg';
import serviceImage from '../../assets/images/service.svg';
import exhaustImage from '../../assets/images/exhaust.svg';
import garageImage from '../../assets/images/garage.svg';

export const headerSelector = (state: AppState): any => state.brochureWare.header;

const serviceItems = {
  itemText: 'services-offered-tab',
  sectionTitle: 'services-offered-tab',
  children: [
    {
      href: 'servicesMot',
      hrefText: 'services-offered-tab-1-text',
      imagePath: motImage,
      altText: 'services-offered-tab-1-alt',
    },
    {
      href: 'servicesBrakes',
      hrefText: 'services-offered-tab-2-text',
      imagePath: brakeImage,
      altText: 'services-offered-tab-2-alt',
    },
    {
      href: 'servicesWheels',
      hrefText: 'services-offered-tab-3-text',
      imagePath: wheelsImage,
      altText: 'services-offered-tab-3-alt',
    },
    {
      href: 'servicesTyres',
      hrefText: 'services-offered-tab-4-text',
      imagePath: tyreImage,
      altText: 'services-offered-tab-4-alt',
    },
    {
      href: 'servicesService',
      hrefText: 'services-offered-tab-5-text',
      imagePath: serviceImage,
      altText: 'services-offered-tab-5-alt',
    },
    {
      href: 'servicesExhaust',
      hrefText: 'services-offered-tab-6-text',
      imagePath: exhaustImage,
      altText: 'services-offered-tab-6-alt',
    },
    {
      href: 'services',
      hrefText: 'services-offered-tab-7-text',
      imagePath: garageImage,
      altText: 'services-offered-tab-7-alt',
    },
  ],
};

const magazineItems = {
  itemText: 'magazine-title-tab',
  sectionTitle: 'magazine-title-tab',
  children: [
    {
      href: 'articles1',
      hrefText: 'magazine-title-tab-1-text',
      imagePath:
        '/content/dam/downstream/carama/carama-turkey/new-consumer-images/blog-images/clutcharticlehero.jpg.transform/quality70/img.jpg',
      altText: 'magazine-title-tab-1-alt',
    },
    {
      href: 'articles2',
      hrefText: 'magazine-title-tab-2-text',
      imagePath:
        '/content/dam/downstream/carama/carama-turkey/new-consumer-images/blog-images/losewaterhero.jpg.transform/quality70/img.jpg',
      altText: 'magazine-title-tab-2-alt',
    },
    {
      href: 'articles3',
      hrefText: 'magazine-title-tab-3-text',
      imagePath:
        '/content/dam/downstream/carama/carama-turkey/new-consumer-images/blog-images/carseatcleanhero.jpg.transform/quality70/img.jpg',
      altText: 'magazine-title-tab-3-alt',
    },
    {
      href: 'articles4',
      hrefText: 'magazine-title-tab-4-text',
      imagePath:
        '/content/dam/downstream/carama/carama-turkey/new-consumer-images/blog-images/oilchangemain.jpg',
      altText: 'magazine-title-tab-4-alt',
    },
    {
      href: 'articles5',
      hrefText: 'magazine-title-tab-5-text',
      imagePath:
        '/content/dam/downstream/carama/carama-turkey/new-consumer-images/blog-images/tyrespeedhero.jpg.transform/quality70/img.jpg',
      altText: 'magazine-title-tab-5-alt',
    },
    {
      href: 'articles6',
      hrefText: 'magazine-title-tab-6-text',
      imagePath:
        '/content/dam/downstream/carama/carama-turkey/blog-imagery/Welcometocaramablogheroimage.jpg.transform/quality70/img.jpg',
      altText: 'magazine-title-tab-6-alt',
    },
  ],
};

const restHeaderItems = [
  {
    itemText: 'about-us-tab',
    href: getLocalisedRoute('aboutUsBlog'),
    sectionTitle: 'about-us-tab',
    children: [],
  },
  {
    itemText: 'faq-tab',
    href: getLocalisedRoute('faq'),
    sectionTitle: 'faq-tab',
    children: [],
  },
  {
    itemText: 'contact-us-tab',
    href: getLocalisedRoute('contactUs'),
    sectionTitle: 'contact-us-tab',
    children: [],
  },
];

export const getHeaderContent = createSelector(headerSelector, (businessHeader) => {
  if (!businessHeader) {
    return [];
  }

  const result = [];
  const serviceItemsCopy = { ...serviceItems };

  serviceItemsCopy.children = serviceItemsCopy.children.map((child) => {
    child.href = getLocalisedRoute(child.href);
    return child;
  });

  result.push(serviceItemsCopy);

  const magazineItemsCopy = { ...magazineItems };
  const { contentFragments } = businessHeader;
  const magazineChildren = contentFragments.map((fragment) => {
    return {
      href: getLocalisedRoute('articles') + fragment.fields?.link,
      hrefText: fragment.fields?.title,
      imagePath: fragment.fields?.bannerImage?.fields.file.url,
      altText: fragment.fields?.bannerImageAltText,
    };
  });

  magazineItemsCopy.children = magazineChildren;

  result.push(magazineItemsCopy);
  result.push(...restHeaderItems);

  return result;
});

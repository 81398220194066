import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { FooterProps } from './Footer';
import { SocialMediaContainer } from './footer-styles';
import { TranslateString } from 'components/Translators';
import facebook from '../../assets/images/svgs/facebook.svg';
import twitter from '../../assets/images/svgs/twitter.svg';
import youtube from '../../assets/images/svgs/youtube.svg';
import instagram from '../../assets/images/svgs/instagram.svg';

const FooterLastRow: React.FC<Partial<FooterProps>> = () => {
  return (
    <Grid container justifyContent={'space-between'}>
      <Grid item>
        <p>
          <Link
            to={TranslateString('lower-footer-item-1-link')}
            aria-label={TranslateString('lower-footer-item-1-aria-label')}
            data-qa="lowerfooter-column1">
            {TranslateString('lower-footer-item-1-title')}
          </Link>
        </p>
      </Grid>
      <Grid item>
        <p>
          <Link
            to={TranslateString('lower-footer-item-2-link')}
            aria-label={TranslateString('lower-footer-item-2-aria-label')}
            data-qa="lowerfooter-column2">
            {TranslateString('lower-footer-item-2-title')}
          </Link>
        </p>
      </Grid>
      <Grid item>
        <p>
          <Link
            to={''}
            aria-label={TranslateString('lower-footer-item-3-aria-label')}
            data-qa="lowerfooter-column3">
            {TranslateString('lower-footer-item-3-title')}
          </Link>
        </p>
      </Grid>
      <Grid item>
        <p>{TranslateString('lower-footer-item-4-title')}</p>
      </Grid>
      <Grid item>
        <p>{TranslateString('lower-footer-item-5-title')}</p>
      </Grid>
      <Grid>
        <SocialMediaContainer>
          <a href={TranslateString('carama-facebook-link')} aria-label={'facebook'}>
            <img src={facebook} alt={TranslateString('carama-facebook-icon-alt')} />
          </a>
          <a href={TranslateString('carama-twitter-link')} aria-label={'twitter'}>
            <img src={twitter} alt={TranslateString('carama-twitter-icon-alt')} />
          </a>
          <a href={TranslateString('carama-youtube-link')} aria-label={'youtube'}>
            <img src={youtube} alt={TranslateString('carama-youtube-icon-alt')} />
          </a>
          <a href={TranslateString('carama-instagram-link')} aria-label={'instagram'}>
            <img src={instagram} alt={TranslateString('carama-instagram-icon-alt')} />
          </a>
        </SocialMediaContainer>
      </Grid>
    </Grid>
  );
};

export default FooterLastRow;

import { all, fork, take, takeEvery } from 'redux-saga/effects';

import { SagaIterator } from 'redux-saga';
import { LoadingActionTypes } from 'modules/loading/loading-actions';
import { ServiceTypeActionTypes } from 'modules/brochureware/services/services-actions';
import { fetchHeaderContent } from '../header/header-saga';
import { fetchPrivacySaga } from './privacy/privacy-saga';
import { fetchServicesSaga, fetchServiceTypeSaga } from './services/services-saga';
import { fetchContactUsSaga } from './contact-us/contact-us-saga';
import { fetchImpressumSaga } from './impressum/impressum-saga';
import { fetchTermsSaga } from './terms/terms-saga';
import { fetchMagazineArticleSaga, fetchMagazineHubSaga } from './magazine/magazine-saga';
import { fetchFaqSaga } from './faq/faq-saga';
import { fetchConsumerPromotionSaga } from './consumer-promotion/consumer-promotion-saga';
import { registerInterestSaga } from './register-interest/saga';

export function* watcherBrochureWare(): SagaIterator {
  yield takeEvery(LoadingActionTypes.FETCH_REFERENCE_DATA, function* () {
    // yield fork(fetchHomeContentSaga);
    yield fork(fetchHeaderContent);
  });
}

const actions = [ServiceTypeActionTypes.FETCH_SERVICE_TYPE_CONTENT];

export function* ServiceTypeSaga(): SagaIterator {
  while (true) {
    const { serviceType } = yield take(actions);
    yield fork(fetchServiceTypeSaga, serviceType);
  }
}

export function* ImpressumSaga(): SagaIterator {
  yield takeEvery(LoadingActionTypes.FETCH_IMPRESSUM_DATA, function* () {
    yield fork(fetchImpressumSaga);
  });
}

export function* TermsSaga(): SagaIterator {
  yield takeEvery(LoadingActionTypes.FETCH_TERMS_DATA, function* () {
    yield fork(fetchTermsSaga);
  });
}

export function* MagazineHubSaga(): SagaIterator {
  yield takeEvery(LoadingActionTypes.FETCH_MAGAZINE_DATA, function* () {
    yield fork(fetchMagazineHubSaga);
  });
}

export function* ConsumerPromotionSaga(): SagaIterator {
  yield takeEvery(LoadingActionTypes.FETCH_CONSUMERPROMOTION_DATA, function* () {
    yield fork(fetchConsumerPromotionSaga);
  });
}

export function* PrivacySaga(): SagaIterator {
  yield takeEvery(LoadingActionTypes.FETCH_PRIVACY_DATA, function* () {
    yield fork(fetchPrivacySaga);
  });
}

export function* ContactUsSaga(): SagaIterator {
  yield takeEvery(LoadingActionTypes.FETCH_CONTACTUS_DATA, function* () {
    yield fork(fetchContactUsSaga);
  });
}

export function* ServiceSaga(): SagaIterator {
  yield takeEvery(LoadingActionTypes.FETCH_SERVICE_DATA, function* () {
    yield fork(fetchServicesSaga);
  });
}

export function* FaqSaga(): SagaIterator {
  yield takeEvery(LoadingActionTypes.FETCH_FAQ_DATA, function* () {
    yield fork(fetchFaqSaga);
  });
}

export default function* BrochurewareSaga(): SagaIterator {
  yield all([
    fork(watcherBrochureWare),
    fork(fetchMagazineArticleSaga),
    fork(FaqSaga),
    fork(registerInterestSaga),
    fork(ImpressumSaga),
    fork(TermsSaga),
    fork(TermsSaga),
    fork(MagazineHubSaga),
    fork(PrivacySaga),
    fork(ServiceSaga),
    fork(ContactUsSaga),
  ]);
}

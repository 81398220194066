import AsyncComponent from 'helpers/AsyncComponent';
import { getLocalisedRoute } from '../../constants/urls';

const routes = [
  {
    path: getLocalisedRoute('contactUs'),
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Brochureware/ContactUs/ContactUsPage')
    ),
  },
  {
    path: getLocalisedRoute('faq'),
    exact: true,
    ViewComponent: AsyncComponent(() => import('../../components/Pages/Brochureware/Faq/FaqPage')),
  },
  {
    path: getLocalisedRoute('terms'),
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Brochureware/Terms/TermsPage')
    ),
  },
  {
    path: getLocalisedRoute('terms') + '/',
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Brochureware/Terms/TermsPage')
    ),
  },
  {
    path: getLocalisedRoute('cookies'),
    exact: true,
    ViewComponent: AsyncComponent(() => import('../../components/Pages/CookiesPage/CookiesPage')),
  },
  {
    path: getLocalisedRoute('aboutUs'),
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Brochureware/AboutUs/AboutUsPage')
    ),
  },
  {
    path: getLocalisedRoute('privacy'),
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Brochureware/Privacy/PrivacyContainer')
    ),
  },
  {
    path: getLocalisedRoute('privacy') + '/',
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Brochureware/Privacy/PrivacyContainer')
    ),
  },
  {
    path: '/blog/',
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Brochureware/Magazine/MagazineHubPage')
    ),
  },
  {
    path: getLocalisedRoute('articles'),
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Brochureware/Magazine/MagazineHubPage')
    ),
  },
  {
    path: `${getLocalisedRoute('articles')}/:articleName`,
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Brochureware/Magazine/MagazineArticle')
    ),
  },
  {
    path: getLocalisedRoute('services'),
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Brochureware/Services/ServicesPage')
    ),
  },
  {
    path: `${getLocalisedRoute('services')}/:serviceType`,
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Brochureware/Services/ServiceType/ServiceType')
    ),
  },
  {
    path: `${getLocalisedRoute('services')}/:serviceType/:serviceSubType`,
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Brochureware/Services/ServiceSubType/ServiceSubType')
    ),
  },
  {
    path: getLocalisedRoute('consumerPromotion'),
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Brochureware/ConsumerPromotion/ConsumerPromotionPage')
    ),
  },
];

export default routes;

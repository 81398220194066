import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { CaramaLogo } from './footer-styles';
import { Hidden } from '@material-ui/core';
import { FooterProps } from './Footer';
import RegionSelectorLink from 'components/RegionSelector/RegionSelectorLink';
import { getLocalisedRoute } from 'constants/urls';
import { TranslateString } from 'components/Translators';

const FooterMiddleRow: React.FC<Partial<FooterProps>> = () => {
  return (
    //someone got columns and rows confused here, data-qa values are correct
    <Grid container justifyContent={'space-between'}>
      <Grid item key={1} xs={6} md={1}>
        <Grid container direction={'column'}>
          <Grid item>
            <h4>{TranslateString('footer-column-1-title')}</h4>
          </Grid>

          <Grid item>
            <Link
              to={TranslateString('footer-column-1-item-1-link')}
              aria-label={TranslateString('footer-column-1-item-1-aria-label')}
              data-qa="footer-column1item1">
              {TranslateString('footer-column-1-item-1-title')}
            </Link>
          </Grid>

          <Grid item>
            <Link
              to={TranslateString('footer-column-1-item-2-link')}
              aria-label={TranslateString('footer-column-1-item-2-aria-label')}
              data-qa="footer-column1item2">
              {TranslateString('footer-column-1-item-2-title')}
            </Link>
          </Grid>

          <Grid item>
            <Link
              to={TranslateString('footer-column-1-item-3-link')}
              aria-label={TranslateString('footer-column-1-item-3-aria-label')}
              data-qa="footer-column1item3">
              {TranslateString('footer-column-1-item-3-title')}
            </Link>
          </Grid>

          {/*Correctly named column/row AEM values*/}
          <Grid item>
            <h4>{TranslateString('footer-column-1-title-2')}</h4>
          </Grid>

          {/*Correctly named column/row AEM values*/}
          <Grid item>
            <a
              href={'/..' + getLocalisedRoute('workshop')}
              aria-label={TranslateString('footer-column-1-item-4-aria-label')}
              data-qa="footer-column1title2item1">
              {TranslateString('footer-column-1-item-4-title')}
            </a>
          </Grid>
        </Grid>
      </Grid>

      <Grid item key={2} xs={6} md={2}>
        <Grid container direction={'column'}>
          <Grid item>
            <h4>{TranslateString('footer-column-2-title')}</h4>
          </Grid>

          <Grid item>
            <a href={'tel:' + TranslateString('footer-column-2-para-1-link')}>
              <p>{TranslateString('footer-column-2-para-1')}</p>
            </a>
          </Grid>

          <Grid item>
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: TranslateString('footer-column-2-para-2'),
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item key={3} xs={12} md={3}>
        <Grid item>
          <div style={{ marginTop: '1rem' }}>
            <RegionSelectorLink />
          </div>
        </Grid>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={3} lg={2}>
          <CaramaLogo />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default FooterMiddleRow;

import React from 'react';
import { CaramaLogo, FooterDivider, StyledFooter, StyledFooterInnerContent } from './footer-styles';
import Grid from '@material-ui/core/Grid';
import { Hidden } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import FooterMiddleRow from './FooterMiddleRow';
import FooterLastRow from './FooterLastRow';

export interface FooterProps {
  content: Record<string, string>;
  classes: Record<string, string>;
  historyState: Record<string, any>;
  fetchWebsiteFooter: Function;
}

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const { content } = props;

  return (
    <>
      <StyledFooter>
        <Grid container>
          <Grid item xs={12}>
            <StyledFooterInnerContent>
              <FooterMiddleRow content={content} />
            </StyledFooterInnerContent>
          </Grid>

          <Grid item xs={12}>
            {/*Tablet only*/}
            <Hidden only={['sm', 'xs', 'lg', 'xl']}>
              <StyledFooterInnerContent>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                  <Grid item md={3}>
                    <CaramaLogo />
                  </Grid>
                </Grid>
              </StyledFooterInnerContent>
            </Hidden>
            <FooterDivider>
              <Divider />
            </FooterDivider>

            <StyledFooterInnerContent>
              <FooterLastRow content={content} />
            </StyledFooterInnerContent>
          </Grid>

          <StyledFooterInnerContent>
            <Hidden mdUp>
              <Grid item xs={3} lg={2}>
                <CaramaLogo />
              </Grid>
            </Hidden>
          </StyledFooterInnerContent>
        </Grid>
      </StyledFooter>
    </>
  );
};

export default Footer;
